
// expose addToCart function to other scripts
import { defineComponent, useContext } from '@nuxtjs/composition-api';
import { useKECart } from '@vue-storefront/novulo';
import { useVSFContext } from '@vue-storefront/core';
import { getCurrencyCode, getBrowserDetails } from '~/helpers/utils';

export default defineComponent({
  setup() {
    const context = useContext();
    const vsfContext = useVSFContext();

    const { addToCart } = useKECart();

    // expose addToCart function to other scripts
    if (process.client) {
      (window as any).vsfAddToCart = (params) => {
        return addToCart({
          ...params,
          currency: getCurrencyCode({ vsfContext }),
          browserDetails: getBrowserDetails({ vsfContext, i18n: context.i18n })
        });
      };
    }
  },
  render() {
    return null;
  }
});
