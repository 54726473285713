


























import { defineComponent, ref, useContext } from '@nuxtjs/composition-api';
import { useVSFContext } from '@vue-storefront/core';
import axios from 'axios';
import useGoogleAnalytics, { eventBuilder } from '~/hooks/useGoogleAnalytics';
import useUrlFormatter from '~/hooks/useUrlFormatter';
import useDebouncer from '~/hooks/useDebouncer';

export default defineComponent({
  setup() {
    const formatUrl = useUrlFormatter();

    const searchQuery = ref('');
    const isFocused = ref(false);
    const isResultEmpty = ref(true);
    const productResult = ref([]);
    const suggestionResult = ref([]);
    const contentResult = ref([]);
    const isLoading = ref(false);

    const { i18n } = useContext();
    const { $novulo } = useVSFContext();
    const { registerEvent } = useGoogleAnalytics();
    const { debounce } = useDebouncer();

    const searchInteractionEvent = eventBuilder('searchInteraction');

    const tweakwiseInstanceKey = $novulo.config.state.getTweakwiseInstanceKey();

    // TODO: Refactor this function at some point into one object
    const handleSearchQuery = (e: string) => {
      searchQuery.value = e;
      debounce(() =>
        registerEvent(
          searchInteractionEvent({ searchTerm: e, interactionType: 'query' })
        )
      );
      if (searchQuery.value !== '' && searchQuery.value.length > 1) {
        searchQuery.value.replaceAll('²', '');
        isLoading.value = true;
        axios
          .get(
            `https://navigator-group1.tweakwise.com/autocomplete/${tweakwiseInstanceKey}?tn_q=${searchQuery.value}&tn_parameters=item_type%3DProduct&format=json`
          )
          .then((response) => {
            suggestionResult.value = response?.data?.suggestions || [];
            productResult.value = response?.data?.items || [];
            isResultEmpty.value =
              productResult.value.length === 0 &&
              suggestionResult.value.length === 0;
            isLoading.value = false;
          })
          .catch((e) => {
            console.log('suggestions,', e);
          });

        axios
          .get(
            `https://navigator-group1.tweakwise.com/autocomplete/${tweakwiseInstanceKey}?format=json&tn_q=${searchQuery.value}&tn_parameters=item_type%3DContent`
          )
          .then((response) => {
            contentResult.value = response.data?.items || [];
          })
          .catch((e) => {
            console.log('content,', e);
          });
      } else {
        productResult.value = [];
        suggestionResult.value = [];
        contentResult.value = [];
        isResultEmpty.value = true;
        isLoading.value = false;
      }
    };

    const createDefaultSearchRedirection = (query: string) => {
      const domainConfig = $novulo.config.state.getDomainConfig();
      const languages = Object.keys(domainConfig.lang);
      const langPathPart =
        languages[0] === i18n.locale ? '' : `/${i18n.locale}`;
      return `${langPathPart}${i18n.t(
        '/search-results'
      )}?tn_q=${query}&tn_parameters=item_type%3DProduct`;
    };

    const handleSearchEnter = (query: string) => {
      axios
        .get(
          `https://navigator-group1.tweakwise.com/products/${tweakwiseInstanceKey}?format=json&tn_q=${query}`
        )
        .then((response) => {
          const redirectUrl = response?.data?.redirects?.[0]?.url;
          if (redirectUrl) {
            registerEvent(
              searchInteractionEvent({
                searchTerm: query,
                interactionType: 'redirect'
              })
            );
            window.location.href = formatUrl(redirectUrl);
          } else {
            registerEvent(
              searchInteractionEvent({
                searchTerm: query,
                interactionType: 'query'
              })
            );
            window.location.href = createDefaultSearchRedirection(query);
          }
        })
        .catch(() => {
          registerEvent(
            searchInteractionEvent({
              searchTerm: query,
              interactionType: 'query'
            })
          );
          window.location.href = createDefaultSearchRedirection(query);
        });
    };

    const handleSuggestionClick = (title: string) => {
      registerEvent(
        searchInteractionEvent({
          searchTerm: searchQuery.value,
          interactionType: 'suggestion_click',
          suggestionName: title
        })
      );
      handleSearchEnter(title);
    };

    const handleProductClick = (product) => {
      registerEvent(
        searchInteractionEvent({
          searchTerm: searchQuery.value,
          interactionType: 'product_click',
          itemId: product.itemsNo,
          itemName: product.title
        })
      );
    };

    const handleContentClick = (content) => {
      registerEvent(
        searchInteractionEvent({
          searchTerm: searchQuery.value,
          interactionType: 'product_click',
          itemId: content.itemsNo,
          itemName: content.title
        })
      );
    };

    const handleMoreProductsClick = () => {
      registerEvent(
        searchInteractionEvent({
          searchTerm: searchQuery.value,
          interactionType: 'query'
        })
      );
      window.location.href = formatUrl(
        `${i18n.t('/search-results')}?tn_q=${
          searchQuery.value
        }&tn_parameters=item_type%3DProduct`
      );
    };

    const handleMoreContentClick = () => {
      registerEvent(
        searchInteractionEvent({
          searchTerm: searchQuery.value,
          interactionType: 'query'
        })
      );
      window.location.href = formatUrl(
        `${i18n.t('/search-results')}?tn_q=${
          searchQuery.value
        }&tn_parameters=item_type%3DContent`
      );
    };

    return {
      searchQuery,
      handleFocus: (e: boolean) => {
        isFocused.value = e;
      },
      isFocused,
      isResultEmpty,
      productResult,
      suggestionResult,
      contentResult,
      isLoading,
      handleSearchQuery,
      handleSearchEnter,
      handleSuggestionClick,
      handleProductClick,
      handleContentClick,
      handleMoreProductsClick,
      handleMoreContentClick
    };
  }
});
