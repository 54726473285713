




















































import { defineComponent } from '@nuxtjs/composition-api';
import { addCurrency, priceFormat } from '~/helpers/utils';

export default defineComponent({
  name: 'CartItem',
  props: {
    item: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  setup() {
    return {
      priceFormat,
      addCurrency
    };
  }
});
