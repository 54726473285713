



import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'SpotlightBar',
  props: {
    content: {
      type: String,
      required: true,
      default: ''
    }
  }
});
